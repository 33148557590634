"use client";

import useSignIn from "@portal/hooks/useSignIn";
import SignInPage from "@portal/components/pages/signInPage";
import { Suspense } from "react";
import PageSpinner from "@portal/components/organisms/pageSpinner";
import LayoutNotAuthorized from "@portal/components/organisms/layouts/Layout-NotAuthorized";

const SigninClientComponent = () => {
  useSignIn();

  return SignInPage({ signInPath: "/auth/signin" });
};

const SigninClientComponentWrapper = () => (
  <Suspense fallback={<PageSpinner />}>
    <LayoutNotAuthorized>
      <SigninClientComponent />
    </LayoutNotAuthorized>
  </Suspense>
);

export default SigninClientComponentWrapper;
