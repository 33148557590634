import { useSearchParams } from "next/navigation";
import { ISignInOptions, IUseSignIn } from "./types";
import { SignInAuthorizationParams, signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { trackMessage } from "@portal/appInsights";

const useSignIn: IUseSignIn = () => {
  const searchParams = useSearchParams();
  const { status, data } = useSession();

  trackMessage(`useSignIn ${status}`);

  useEffect(() => {
    if (!searchParams?.get("error")) {
      if (status === "unauthenticated") {
        const signInOptions = {} as ISignInOptions;
        const url = searchParams?.get("callbackUrl")
          ? new URL(searchParams?.get("callbackUrl") as string)
          : new URL(`${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`);
        const centerId = url.searchParams.get("centerId");
        const startoverurl = searchParams?.get("startoverurl") as string;

        if (centerId) {
          signInOptions.logonwithcenterid = centerId;
        }
        const extraParams = new URLSearchParams();
        if (searchParams) {
          for (const key of searchParams.keys()) {
            if (key !== "startoverurl" && key !== "callbackUrl") {
              extraParams.append(key, searchParams?.get(key) as string);
              url.searchParams.append(key, searchParams?.get(key) as string);
            }
            if (key === "startoverurl" && searchParams?.get(key)?.indexOf("?")) {
              const param = (searchParams?.get(key) as string).split("?")[1];
              if (param.indexOf("=")) {
                const paramArray = param.split("=");
                url.searchParams.append(paramArray[0], paramArray[1] as string);
              }
            }
          }
        }

        if (startoverurl) {
          const extraSearch = startoverurl.includes("?") ? `&${extraParams.toString()}` : `?${extraParams.toString()}`;
          signInOptions.startoverurl = encodeURIComponent(startoverurl + extraSearch);
        }
        trackMessage("Sign in with identity-server4");
        signIn("identity-server4", { callbackUrl: url.toString() }, signInOptions as SignInAuthorizationParams);
      } else if (status === "authenticated") {
        window.location.href = searchParams?.get("callbackUrl")
          ? (searchParams?.get("callbackUrl") as string)
          : `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}/`;
      }
    }
  }, [status, data, searchParams]);
};

export default useSignIn;
